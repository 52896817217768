import { Layout, Menu } from "antd";
import { FC } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import { SidebarProps } from "./types";
import { useSelector } from "react-redux";

// Global sidebar
const Sidebar: FC<SidebarProps> = (props) => {
  // Initialize variables and state
  const { Sider } = Layout;
  const { handleSidebar, items, selectedSidebar } = props;

  const profileData = useSelector((state: any) => state?.userProfile?.data);

  // filter above item based on profileData role
  const filteredItems = items?.filter((item: any) => {
    if (
      profileData?.companyRoles[0]?.role?.roleName === "Service Center Employee"
    ) {
      return item.label !== "Item Inventory";
    } else if (profileData?.companyRoles[0]?.role?.roleName === "Employee") {
      return item.label;
    }
    return true;
  });

  // JSX
  return (
    <Sider
      style={{
        background: "#fff",
        maxHeight: "100%",
        height: "100%",
        overflow: "auto",
      }}
      className={styles.sidebar}
    >
      <div className={styles.sidebar__wrapper}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[selectedSidebar]}
          selectedKeys={[selectedSidebar]}
          items={filteredItems}
          onClick={handleSidebar}
          className="menu-item "
          style={{
            background: "#fff",
            padding: 0,
          }}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
